<template>
  <base-layout :loadingFlag="$store.state.loading">
    <breadcrumbs :links="links" />
    <validation-observer v-slot="{ invalid }">
      <h1 class="title">Payment Form</h1>
      <div class="field">
        <payment-form
          :showAmount="false"
          :payInfo="pi"
          @payment-info-updated="updatePaymentInfo"
        />
      </div>
      <div class="field">
        <hr />
      </div>
      <div class="buttons">
        <button
          class="button is-success"
          @click="submit"
          :disabled="$store.state.loading || invalid"
        >
          Continue
        </button>
        <button class="button is-danger" @click="deletePendingPPA">
          Cancel
        </button>
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import {mapState, mapActions, mapGetters} from "vuex";
import BaseLayout from "@/components/BaseLayout";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PaymentForm from "@/components/payment/PaymentForm.vue";
import store from "@/store/index";
import { ValidationObserver } from "vee-validate";
import isValidPrefill from "@/utils/isValidPrefill.js"

function unloadListener(event) {
  event.preventDefault();
  event.returnValue = "";
}

export default {
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapState("rege", ["rege"]),
    ...mapState("arr", ["paymentInfo", "parameters"]),
    ...mapState("pay", ["feeResult"]),
    ...mapGetters(["settlementAcceptanceLabel"]),
    pi() {
      if (this.$store.state.pay.hasPrefilledPayInfo) {

        if (Number(this.$store.state.pay.prefilledPayInfo.paymentOption) === 0
            || Number(this.$store.state.pay.prefilledPayInfo.paymentOption) === 2) {

          const ccExpDate = this.$store.state.pay.prefilledPayInfo.ccExpDate

          if (ccExpDate && ccExpDate.length === 4) {
            const month = ccExpDate.substring(0, 2)
            const year = "20" + ccExpDate.substring(2)

            if (isValidPrefill(new Date(), year, month)) {
              return this.$store.state.pay.prefilledPayInfo;
            }

          }
        } else {
          return this.$store.state.pay.prefilledPayInfo
        }
      }
      return null
    }
  },
  components: { BaseLayout, Breadcrumbs, PaymentForm, ValidationObserver },
  data() {
    return {
      links: []
    };
  },
  created() {
    this.links = [
      { label: "Home", icon: "home", to: { name: "home" } },
      {
        label: "Make Payment",
        icon: "dollar-sign",
        to: { name: "makePayment", params: { id: this.$route.params.id } }
      },
      {
        label: this.instanceConfig.overTimeTitle,
        icon: "calendar",
        to: { name: "overTime", params: { id: this.$route.params.id } }
      },
      {
        label: this.settlementAcceptanceLabel,
        icon: "check",
        to: {
          name: "amortizationSchedule",
          params: { id: this.$route.params.id }
        }
      },
      {
        label: "Payment",
        icon: null,
        to: { name: "overTime.payment", params: { id: this.$route.params.id } },
        isActive: true
      }
    ];
  },
  beforeRouteEnter(to, from, next) {
    store.commit("SET_LOADING", false); // leftover from prior route
    window.addEventListener("beforeunload", unloadListener);
    if (
      store.state.instanceConfig &&
      Object.keys(store.state.instanceConfig).length === 0 &&
      store.state.instanceConfig.constructor === Object
    ) {
      next({
        name: "overTime",
        params: { instanceId: to.params.instanceId, id: to.params.id }
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  },
  methods: {
    ...mapActions("rege", ["fetchRegEMessages"]),
    ...mapActions("arr", ["deleteArrangement", "previewArrangement"]),
    async submit() {
      window.scrollTo(0, 0);
      this.$store.commit("SET_LOADING", true);
      try {
        let phrType = "4";
        if (
          this.paymentInfo.paymentOption == 1 ||
          this.paymentInfo.paymentOption == 2
        ) {
          phrType = "1";
        }

        await store.dispatch("pay/calculateFee", {
          account: this.$route.params.id,
          phrType: phrType,
          amount: this.parameters.paymentAmount
        });

        if (this.feeResult && this.feeResult.success) {
          // recalc the payment arrangement preview w. fee
          await this.previewArrangement({
            username: this.$store.state.username,
            jwt: this.$store.state.jwt,
            accountNo: this.$route.params.id,
            paymentAmount: this.$store.state.parrng.paymentAmount,
            numberOfPayments: this.$store.state.parrng.numberOfPayments,
            firstPaymentDate: this.$store.state.parrng.firstPaymentDate,
            planCode: this.$store.state.instanceConfig.pprCode,
            totalAmount: this.parameters.paymentAmount,
            timezone: (-1 * new Date().getTimezoneOffset()) / 60,
            fee: this.feeResult.amount
          });

          await this.fetchRegEMessages({
            option: this.paymentInfo.paymentOption,
            action: "next",
            relatedAccount: this.$route.params.id
          });

          if (this.rege != null && this.rege.length > 0) {
            this.$router.push({ name: "overTime.regE" });
          } else {
            this.$router.push({ name: "overTime.finalReview" });
          }
        } else {
          this.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage: `Error calculating fee ${
              this.feeResult.errorMessage
            }`,
            notificationType: "error"
          });
        }
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },
    async deletePendingPPA() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.deleteArrangement();
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }

      this.$router.push({ name: "overTime" });
    },
    updatePaymentInfo(payload) {
      this.$store.commit("arr/SET_PAYMENT_INFO", payload);
    }
  }
};
</script>
